@import "styleSheetVariables";
.auth {
  background: $appBackground;
}
.selectedNode > div:first-child {
  color: $lightNavy2;
}

.auth-left-panel {
  @include auth-background;
  border-radius: 12px 0px 0px 12px;
}

.mobileHeader {
  @include auth-background;
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 160px;
  justify-content: center;
}

.auth-right-panel {
  @include auth-container;
  height: $fullHeight;
  overflow-x: hidden;
  overflow-y: visible;
  border-radius: 12px 12px 12px 12px;
  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

/* Modal css */
.modal-header {
  padding: 15px 50px 15px 30px;
  position: relative;
  flex: 0 0 auto;
  @include border("bottom", 1px, solid, $lightBorder);
}

.modal-action {
  padding: 15px 30px;
  @include border("top", 1px, solid, $lightBorder);
  flex: 0 0 auto;
  & :not(:first-child) {
    margin-left: 10px;
  }
}

.modal-content {
  padding: 15px 30px;
  overflow-y: auto;
  flex: 1 1 auto;
}
.modal-image {
  @include modal-base;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
}
.modal-sm {
  @include modal-base;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  max-width: 600px;
  margin: 40px auto;
}

.modal-md {
  @include modal-base;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  max-width: 900px;
  margin: 40px auto;
}

.modal-lg {
  @include modal-base;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  max-width: 1280px;
  margin: 40px auto;
}

.modal-xl {
  @include modal-base;
  width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  max-width: 1920px;
}

.modal {
  display: $displayFlex;

  form {
    display: $displayBlock;
    width: $fullWidth;
  }

  .modal-container {
    display: $displayFlex;
    flex-direction: $flexDirectionColumn;
    max-height: $fullHeight;
    width: $fullWidth;
  }
}

/*custom Chips*/
.custom-chips {
  margin-right: 5px;
  background: #f9f9f9;
  padding: 0 10px;
  @include border-default;
}

.hidden {
  display: $none !important;
}

.flex-column {
  flex-direction: $flexDirectionColumn !important;
}

.badgeIconsLeft {
  position: $positionAbsolute;
  top: $spacingZero;
  left: $spacingZero;
  @include modal-base;
}
.badgeIconsRight {
  position: $positionAbsolute;
  top: $spacingZero;
  right: $spacingZero;
  @include modal-base;
}

span.error {
  color: $dangerColor;
  width: $fullWidth;
  text-align: $alignLeft;
  font-size: 12px;
  display: $displayBlock;
}

.colorpicker-container {
  position: $positionAbsolute;
  z-index: 99;
  bottom: 0;
  left: 150px;
}
.color-picker {
  border: 3px solid $lightBorder;
  background-color: #eee;
  cursor: $pointer;
  padding: 6px 10px;
}
.color-picker-dot {
  border: 3px solid $defaultGrey;
  background-color: #eee;
  padding: 6px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}

.thumbnail-card.active {
  border: 1px solid #f00;
}
.imgf {
  max-height: $fullHeight;
  max-width: $fullWidth;
}
.imgm {
  max-height: $fullHeight;
  height: 100px;
  max-width: $fullWidth;
}
.productImage {
  max-height: 200px;
  max-width: 200px;
}

.canvas-area {
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
}

#area-design {
  cursor: move;
  height: 317px;
  left: 135px;
  position: absolute;
  top: 90px;
  width: 230px;
  background: rgba(0, 0, 0, 0.5);
  //background: url(../images/bg-area.png);
}

.ui-resizable-handle {
  background-color: #fff;
  border: 2px solid #428bca;
  height: 10px;
  width: 10px;
}

.toggle-label-left {
  margin-right: 10px;
  cursor: $pointer;
}
.toggle-label-right {
  margin-left: 10px;
  cursor: $pointer;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 9px !important;
  height: 9px !important;
  right: -4px !important;
  bottom: -4px !important;
  background-image: none;
}
.HelpIcon ~ .withHelp {
  right: 24px;
}
.helpverbiage {
  position: $positionRelative;
}
.help-icon {
  cursor: default;
}

.info-icon {
  position: absolute;
  top: 50px;
  right: 15px;
}

.tableSortIcon {
  font-size: 20px !important;
}

.no-data {
  width: 100%;
  text-align: center !important;
}

.expired {
  background: red;
}
.expiring {
  background: yellow;
}
.overflow-text {
  @include overflow-ellipsis;
}

.grid-typography {
  display: flex;
  align-items: center;
  font-size: 20px !important;
  svg {
    margin-right: 10px;
  }
}

.side-menu-link {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
}

.side-menu-link:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}
.fb-formbuilder .side-menu-link:hover {
  text-decoration: none;
  background-color: rgba(237, 231, 246, 0.8);
}
.side-menu-div {
  padding: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
#outlined-basic {
  padding: 12px;
  text-align: center;
}
#outlined-basic1 {
  padding: 12px;
}
.customTable {
  border-top: solid 1px #ddd;
  border-left: solid 1px #ddd;
  margin-bottom: 10px !important;
}
.customTable > div {
  border-bottom: solid 1px #ddd;
  border-right: solid 1px #ddd;
  padding: 8px 15px !important;
}

.dashboardTiles {
  .tiles-capsule {
    box-shadow: 0 0 5px #ddd;
    @include for-phone-only {
      min-height: 100%;
    }
    h2 {
      padding: 0;
    }
    h5 {
      padding: 15px 0 0;
    }
    .tiles-head {
      color: #fff;
      min-height: 15vh;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      transition: all 0.7s ease-in-out;
      overflow: hidden;
      border-radius: 8px 8px 8px 8px;
      position: relative;
      text-align: center;
      &:after {
        content: "";
        opacity: 0.1;
        background-size: cover;
        transition: transform 0.7s ease-in-out;
        position: absolute;
        width: 100%;
        height: 130%;
      }
    }
    .tiles-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  $headNested: " .tiles-head";
  $cardPrefix: serviceCard_;
  .#{$cardPrefix} {
    &loginAdmin #{$headNested} {
      background-color: #6072d2;
      &:after {
        background: url("../../../assets/img/squares.svg") no-repeat center
          center;
        background-size: cover;
        transform: scale(1) rotate(135deg);
        opacity: 0.1;
      }
      &:hover {
        &:after {
          transform: scale(1.2) rotate(90deg);
        }
      }
    }
    &loginPatient #{$headNested} {
      background-color: #3b4b98;
      &:after {
        background: url("../../../assets/img/triangles.svg") no-repeat center
          center;
        background-size: cover;
        transform: scale(1) rotate(90deg);
        opacity: 0.1;
      }
      &:hover {
        &:after {
          transform: scale(1.2) rotate(90deg);
        }
      }
    }
  }
}
.serviceCardList {
  // light background
  .serviceCard {
    width: 50%;
    @include for-phone-only {
      width: 100%;
    }
    @include for-big-desktop-up {
      width: 33.33%;
    }
  }
  @for $i from 1 through max(2) {
    .serviceCard:first-child:nth-last-child(#{$i}),
    .serviceCard:first-child:nth-last-child(#{$i}) ~ .serviceCard {
      @include for-phone-only {
        width: calc(100% / #{$i / 1});
      }
      @include for-touch-devices-only {
        width: calc(100% / #{$i / 1});
      }
    }
  }
}
