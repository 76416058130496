$lightNavy: #566cd6; /*Heading color*/
$lightGrey: #a1a0ae;
$lightNavy2: #6072d2;
$loginBackground: #efeff4;
$mutedColor: #888888;
$coffeeColor: #343434;
$whiteColor: #fff;
$appBackground: #f0f0f7;
$lightBorder: #e8e8e8;
$defaultGrey: #e0e0e0;
$dangerColor: #f44336;
$warningColor: #fff8cb;
$alertColor: #fcd6d3;
$alignAuto: auto;
$fullWidth: 100%;
$fullHeight: 100%;
$fortyWidth: 60%;
$sixtyWidth: 60%;
$seventyWidth: 70%;

$alignLeft: left;
$alignRight: right;
$alignCenter: center;
$alignAuto: auto;
$alignTop: top;
$alignBottom: bottom;
$alignMiddle: middle;

$spacingZero: 0;
$spacingOne: 1rem;
$spacingTwo: 2rem;

$positionAbsolute: absolute;
$positionRelative: relative;
$positionStatic: static;
$positionFixed: fixed;

// Display
$displayFlex: flex;
$displayBlock: block;
$displayInlineBlock: inline-block;
$spaceBetween: space-between;
$flexDirectionColumn: column;
$flexDirectionRow: row;
$alignItemsStart: flex-start;
$alignItemsEnd: flex-end;
// Cursor
$pointer: pointer;
$cursorMove: move;

// None value
$none: none;

// Mixin display row
@mixin display-flex-row {
  display: $displayFlex;
  flex-direction: $flexDirectionRow;
}
// Mixin display column
@mixin display-flex-column {
  display: $displayFlex;
  flex-direction: $flexDirectionColumn;
}

@mixin auth-background {
  background-color: $loginBackground;
  background-image: url("../../../assets/img/login-bg.png");
  background-repeat: no-repeat;
  background-position: center bottom -400px;
}

@mixin auth-container {
  background: $whiteColor;
  padding: 30px;
}

@mixin cpd-app-background {
  background-image: url("../images/background-cpd.jpg");
  background-repeat: repeat;
  background-position: left top;
}
@mixin cert-app-background {
  // background-image: url("../images/certmanager-bg.jpg") ;
  background-color: #f4f6f9;
  background-repeat: no-repeat;
  background-position: left bottom;
}
/* borders */
@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}

@mixin border-default {
  border: 1px solid $lightBorder;
}
@mixin box-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

/* Modal css */
@mixin modal-base {
  background-color: $whiteColor;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  outline: 0;
  padding: 0;
}

/* SCSS variables to store breakpoints media*/
$mediaXlWidth: 1920px;
$mediaLgWidth: 1280px;
$mediaMdWidth: 960px;
$mediaSmWidth: 600px;
$mediaXsWidth: 320px;
$mediaLaptopWidth: 1440px;

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-touch-devices-only {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: $displayInlineBlock;
}

@mixin flexAuto {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

* {
  list-style: none;
}

ul {
  margin: $spacingZero;
  padding: $spacingZero;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $whiteColor inset !important;
}

html,
body {
  height: 100vh;
  padding-right: 0 !important;
}
