// Country phone number css
.no-underline:after,
.no-underline:before {
  opacity: 0;
}
.country-list {
  display: flex;
  align-items: center;
}
.country-list .country-name {
  display: none;
}
.react-tel-input {
  font-size: 14px;
  position: relative;
  width: 100%;
}
.react-tel-input :disabled {
  cursor: not-allowed;
}
.country-flag {
  width: 16px;
  height: 11px;
  margin-right: 5px;
  background: url(../img/country-flag.png) no-repeat;
}
.react-tel-input .ad {
  background-position: -16px 0;
}
.react-tel-input .ae {
  background-position: -32px 0;
}
.country-flag.af {
  background-position: -48px 0;
}
.country-flag.ag {
  background-position: -64px 0;
}
.country-flag.ai {
  background-position: -80px 0;
}
.country-flag.al {
  background-position: -96px 0;
}
.country-flag.am {
  background-position: -112px 0;
}
.country-flag.ao {
  background-position: -128px 0;
}
.country-flag.ar {
  background-position: -144px 0;
}
.country-flag.as {
  background-position: -160px 0;
}
.country-flag.at {
  background-position: -176px 0;
}
.country-flag.au {
  background-position: -192px 0;
}
.country-flag.aw {
  background-position: -208px 0;
}
.country-flag.az {
  background-position: -224px 0;
}
.country-flag.ba {
  background-position: -240px 0;
}
.country-flag.bb {
  background-position: 0 -11px;
}
.country-flag.bd {
  background-position: -16px -11px;
}
.country-flag.be {
  background-position: -32px -11px;
}
.country-flag.bf {
  background-position: -48px -11px;
}
.country-flag.bg {
  background-position: -64px -11px;
}
.country-flag.bh {
  background-position: -80px -11px;
}
.country-flag.bi {
  background-position: -96px -11px;
}
.country-flag.bj {
  background-position: -112px -11px;
}
.country-flag.bm {
  background-position: -128px -11px;
}
.country-flag.bn {
  background-position: -144px -11px;
}
.country-flag.bo {
  background-position: -160px -11px;
}
.country-flag.br {
  background-position: -176px -11px;
}
.country-flag.bs {
  background-position: -192px -11px;
}
.country-flag.bt {
  background-position: -208px -11px;
}
.country-flag.bw {
  background-position: -224px -11px;
}
.country-flag.by {
  background-position: -240px -11px;
}
.country-flag.bz {
  background-position: 0 -22px;
}
.country-flag.ca {
  background-position: -16px -22px;
}
.country-flag.cd {
  background-position: -32px -22px;
}
.country-flag.cf {
  background-position: -48px -22px;
}
.country-flag.cg {
  background-position: -64px -22px;
}
.country-flag.ch {
  background-position: -80px -22px;
}
.country-flag.ci {
  background-position: -96px -22px;
}
.country-flag.ck {
  background-position: -112px -22px;
}
.country-flag.cl {
  background-position: -128px -22px;
}
.country-flag.cm {
  background-position: -144px -22px;
}
.country-flag.cn {
  background-position: -160px -22px;
}
.country-flag.co {
  background-position: -176px -22px;
}
.country-flag.cr {
  background-position: -192px -22px;
}
.country-flag.cu {
  background-position: -208px -22px;
}
.country-flag.cv {
  background-position: -224px -22px;
}
.country-flag.cw {
  background-position: -240px -22px;
}
.country-flag.cy {
  background-position: 0 -33px;
}
.country-flag.cz {
  background-position: -16px -33px;
}
.country-flag.de {
  background-position: -32px -33px;
}
.country-flag.dj {
  background-position: -48px -33px;
}
.country-flag.dk {
  background-position: -64px -33px;
}
.country-flag.dm {
  background-position: -80px -33px;
}
.country-flag.do {
  background-position: -96px -33px;
}
.country-flag.dz {
  background-position: -112px -33px;
}
.country-flag.ec {
  background-position: -128px -33px;
}
.country-flag.ee {
  background-position: -144px -33px;
}
.country-flag.eg {
  background-position: -160px -33px;
}
.country-flag.er {
  background-position: -176px -33px;
}
.country-flag.es {
  background-position: -192px -33px;
}
.country-flag.et {
  background-position: -208px -33px;
}
.country-flag.fi {
  background-position: -224px -33px;
}
.country-flag.fj {
  background-position: -240px -33px;
}
.country-flag.fk {
  background-position: 0 -44px;
}
.country-flag.fm {
  background-position: -16px -44px;
}
.country-flag.fo {
  background-position: -32px -44px;
}
.country-flag.fr,
.country-flag.bl,
.country-flag.mf {
  background-position: -48px -44px;
}
.country-flag.ga {
  background-position: -64px -44px;
}
.country-flag.gb {
  background-position: -80px -44px;
}
.country-flag.gd {
  background-position: -96px -44px;
}
.country-flag.ge {
  background-position: -112px -44px;
}
.country-flag.gf {
  background-position: -128px -44px;
}
.country-flag.gh {
  background-position: -144px -44px;
}
.country-flag.gi {
  background-position: -160px -44px;
}
.country-flag.gl {
  background-position: -176px -44px;
}
.country-flag.gm {
  background-position: -192px -44px;
}
.country-flag.gn {
  background-position: -208px -44px;
}
.country-flag.gp {
  background-position: -224px -44px;
}
.country-flag.gq {
  background-position: -240px -44px;
}
.country-flag.gr {
  background-position: 0 -55px;
}
.country-flag.gt {
  background-position: -16px -55px;
}
.country-flag.gu {
  background-position: -32px -55px;
}
.country-flag.gw {
  background-position: -48px -55px;
}
.country-flag.gy {
  background-position: -64px -55px;
}
.country-flag.hk {
  background-position: -80px -55px;
}
.country-flag.hn {
  background-position: -96px -55px;
}
.country-flag.hr {
  background-position: -112px -55px;
}
.country-flag.ht {
  background-position: -128px -55px;
}
.country-flag.hu {
  background-position: -144px -55px;
}
.country-flag.id {
  background-position: -160px -55px;
}
.country-flag.ie {
  background-position: -176px -55px;
}
.country-flag.il {
  background-position: -192px -55px;
}
.country-flag.in {
  background-position: -208px -55px;
}
.country-flag.io {
  background-position: -224px -55px;
}
.country-flag.iq {
  background-position: -240px -55px;
}
.country-flag.ir {
  background-position: 0 -66px;
}
.country-flag.is {
  background-position: -16px -66px;
}
.country-flag.it {
  background-position: -32px -66px;
}
.country-flag.je {
  background-position: -144px -154px;
}
.country-flag.jm {
  background-position: -48px -66px;
}
.country-flag.jo {
  background-position: -64px -66px;
}
.country-flag.jp {
  background-position: -80px -66px;
}
.country-flag.ke {
  background-position: -96px -66px;
}
.country-flag.kg {
  background-position: -112px -66px;
}
.country-flag.kh {
  background-position: -128px -66px;
}
.country-flag.ki {
  background-position: -144px -66px;
}
.country-flag.xk {
  background-position: -128px -154px;
}
.country-flag.km {
  background-position: -160px -66px;
}
.country-flag.kn {
  background-position: -176px -66px;
}
.country-flag.kp {
  background-position: -192px -66px;
}
.country-flag.kr {
  background-position: -208px -66px;
}
.country-flag.kw {
  background-position: -224px -66px;
}
.country-flag.ky {
  background-position: -240px -66px;
}
.country-flag.kz {
  background-position: 0 -77px;
}
.country-flag.la {
  background-position: -16px -77px;
}
.country-flag.lb {
  background-position: -32px -77px;
}
.country-flag.lc {
  background-position: -48px -77px;
}
.country-flag.li {
  background-position: -64px -77px;
}
.country-flag.lk {
  background-position: -80px -77px;
}
.country-flag.lr {
  background-position: -96px -77px;
}
.country-flag.ls {
  background-position: -112px -77px;
}
.country-flag.lt {
  background-position: -128px -77px;
}
.country-flag.lu {
  background-position: -144px -77px;
}
.country-flag.lv {
  background-position: -160px -77px;
}
.country-flag.ly {
  background-position: -176px -77px;
}
.country-flag.ma {
  background-position: -192px -77px;
}
.country-flag.mc {
  background-position: -208px -77px;
}
.country-flag.md {
  background-position: -224px -77px;
}
.country-flag.me {
  background-position: -112px -154px;
  height: 12px;
}
.country-flag.mg {
  background-position: 0 -88px;
}
.country-flag.mh {
  background-position: -16px -88px;
}
.country-flag.mk {
  background-position: -32px -88px;
}
.country-flag.ml {
  background-position: -48px -88px;
}
.country-flag.mm {
  background-position: -64px -88px;
}
.country-flag.mn {
  background-position: -80px -88px;
}
.country-flag.mo {
  background-position: -96px -88px;
}
.country-flag.mp {
  background-position: -112px -88px;
}
.country-flag.mq {
  background-position: -128px -88px;
}
.country-flag.mr {
  background-position: -144px -88px;
}
.country-flag.ms {
  background-position: -160px -88px;
}
.country-flag.mt {
  background-position: -176px -88px;
}
.country-flag.mu {
  background-position: -192px -88px;
}
.country-flag.mv {
  background-position: -208px -88px;
}
.country-flag.mw {
  background-position: -224px -88px;
}
.country-flag.mx {
  background-position: -240px -88px;
}
.country-flag.my {
  background-position: 0 -99px;
}
.country-flag.mz {
  background-position: -16px -99px;
}
.country-flag.na {
  background-position: -32px -99px;
}
.country-flag.nc {
  background-position: -48px -99px;
}
.country-flag.ne {
  background-position: -64px -99px;
}
.country-flag.nf {
  background-position: -80px -99px;
}
.country-flag.ng {
  background-position: -96px -99px;
}
.country-flag.ni {
  background-position: -112px -99px;
}
.country-flag.nl,
.country-flag.bq {
  background-position: -128px -99px;
}
.country-flag.no {
  background-position: -144px -99px;
}
.country-flag.np {
  background-position: -160px -99px;
}
.country-flag.nr {
  background-position: -176px -99px;
}
.country-flag.nu {
  background-position: -192px -99px;
}
.country-flag.nz {
  background-position: -208px -99px;
}
.country-flag.om {
  background-position: -224px -99px;
}
.country-flag.pa {
  background-position: -240px -99px;
}
.country-flag.pe {
  background-position: 0 -110px;
}
.country-flag.pf {
  background-position: -16px -110px;
}
.country-flag.pg {
  background-position: -32px -110px;
}
.country-flag.ph {
  background-position: -48px -110px;
}
.country-flag.pk {
  background-position: -64px -110px;
}
.country-flag.pl {
  background-position: -80px -110px;
}
.country-flag.pm {
  background-position: -96px -110px;
}
.country-flag.pr {
  background-position: -112px -110px;
}
.country-flag.ps {
  background-position: -128px -110px;
}
.country-flag.pt {
  background-position: -144px -110px;
}
.country-flag.pw {
  background-position: -160px -110px;
}
.country-flag.py {
  background-position: -176px -110px;
}
.country-flag.qa {
  background-position: -192px -110px;
}
.country-flag.re {
  background-position: -208px -110px;
}
.country-flag.ro {
  background-position: -224px -110px;
}
.country-flag.rs {
  background-position: -240px -110px;
}
.country-flag.ru {
  background-position: 0 -121px;
}
.country-flag.rw {
  background-position: -16px -121px;
}
.country-flag.sa {
  background-position: -32px -121px;
}
.country-flag.sb {
  background-position: -48px -121px;
}
.country-flag.sc {
  background-position: -64px -121px;
}
.country-flag.sd {
  background-position: -80px -121px;
}
.country-flag.se {
  background-position: -96px -121px;
}
.country-flag.sg {
  background-position: -112px -121px;
}
.country-flag.sh {
  background-position: -128px -121px;
}
.country-flag.si {
  background-position: -144px -121px;
}
.country-flag.sk {
  background-position: -160px -121px;
}
.country-flag.sl {
  background-position: -176px -121px;
}
.country-flag.sm {
  background-position: -192px -121px;
}
.country-flag.sn {
  background-position: -208px -121px;
}
.country-flag.so {
  background-position: -224px -121px;
}
.country-flag.sr {
  background-position: -240px -121px;
}
.country-flag.ss {
  background-position: 0 -132px;
}
.country-flag.st {
  background-position: -16px -132px;
}
.country-flag.sv {
  background-position: -32px -132px;
}
.country-flag.sx {
  background-position: -48px -132px;
}
.country-flag.sy {
  background-position: -64px -132px;
}
.country-flag.sz {
  background-position: -80px -132px;
}
.country-flag.tc {
  background-position: -96px -132px;
}
.country-flag.td {
  background-position: -112px -132px;
}
.country-flag.tg {
  background-position: -128px -132px;
}
.country-flag.th {
  background-position: -144px -132px;
}
.country-flag.tj {
  background-position: -160px -132px;
}
.country-flag.tk {
  background-position: -176px -132px;
}
.country-flag.tl {
  background-position: -192px -132px;
}
.country-flag.tm {
  background-position: -208px -132px;
}
.country-flag.tn {
  background-position: -224px -132px;
}
.country-flag.to {
  background-position: -240px -132px;
}
.country-flag.tr {
  background-position: 0 -143px;
}
.country-flag.tt {
  background-position: -16px -143px;
}
.country-flag.tv {
  background-position: -32px -143px;
}
.country-flag.tw {
  background-position: -48px -143px;
}
.country-flag.tz {
  background-position: -64px -143px;
}
.country-flag.ua {
  background-position: -80px -143px;
}
.country-flag.ug {
  background-position: -96px -143px;
}
.country-flag.us {
  background-position: -112px -143px;
}
.country-flag.uy {
  background-position: -128px -143px;
}
.country-flag.uz {
  background-position: -144px -143px;
}
.country-flag.va {
  background-position: -160px -143px;
}
.country-flag.vc {
  background-position: -176px -143px;
}
.country-flag.ve {
  background-position: -192px -143px;
}
.country-flag.vg {
  background-position: -208px -143px;
}
.country-flag.vi {
  background-position: -224px -143px;
}
.country-flag.vn {
  background-position: -240px -143px;
}
.country-flag.vu {
  background-position: 0 -154px;
}
.country-flag.wf {
  background-position: -16px -154px;
}
.country-flag.ws {
  background-position: -32px -154px;
}
.country-flag.ye {
  background-position: -48px -154px;
}
.country-flag.za {
  background-position: -64px -154px;
}
.country-flag.zm {
  background-position: -80px -154px;
}
.country-flag.zw {
  background-position: -96px -154px;
}
.react-tel-input .selected-flag {
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.react-tel-input .country-list .flag {
  display: inline-block;
}
.react-tel-input .country-list .country {
  padding: 7px 9px;
}
.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
  font-size: 14px;
}
.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .flag {
  margin-right: 7px;
  margin-top: 2px;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
}
