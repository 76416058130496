.rccs__card {
    height: 158.873px;
    margin: 0 auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 230px;
}
.rccs__number {
    clear: both;
    font-family: "Consolas", "Courier", monospace;
    font-size: 11px;
    left: 10%;
    position: absolute;
    top: 45%;
}
.rccs__name {
    bottom: 15%;
    font-family: "Consolas", "Courier", monospace;
    font-size: 11px;
    left: 10%;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 60%;
}
.rccs__expiry__valid {
    font-size: 10px;
    margin-bottom: 5px;
}
.rccs__expiry__value {
    font-family: "Consolas", "Courier", monospace;
    font-size: 11px;
}